.leads-view {
  color: #000;
  overflow-x: hidden;

  .leads-div-container {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-x: hidden;
    // padding-bottom: 60px;
  }

  .oddcontainer {
    padding-bottom: 1px;
  }
  .evenContainer {
    padding-bottom: 60px;
  }

  .leads-div {
    // margin: 20px;
    // gap: 15px;
    // display: flex;
    // justify-content: center;
    // position: absolute;
    // top: 15%;
    // padding-bottom: 20px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 15px;
    grid-column-gap: 15px;
    margin: 20px;
    justify-items: center;
    // margin-top: 18%;
    margin-top: 15%;

    .dynamic-div {
      // flex: 0 0 calc(50% - 50px);
      // padding: 15px 12px;
      // text-align: center;
      // font-size: 16px;
      // font-weight: bold;
      // min-width: 160px;
      // height: 215px;
      //-----------------------------------------
      // background-color: rgba(0,0,0,0.4);
      // cursor: pointer;
      // padding: 15px 12px;
      // display: flex;
      // text-align: center;
      // flex-direction: column;
      // justify-content: space-between;
      // position: relative;
      // border-radius: 12px;
      // height: 240px;
      // min-width: 160px;
      // overflow: hidden;
      //-----------------------------------

      // background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      padding: 15px 12px;
      display: flex;
      text-align: center;
      flex-direction: column;
      // justify-content: space-between;
      position: relative;
      border-radius: 12px;
      height: 240px;
      min-width: 160px;
      overflow: hidden;
      // background-color: gray;
      // background: #ffffff29;
      // background-size: cover;

      // border-radius: 12px;

      //  position: relative;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   overflow: hidden;
      //   border-radius: 12px;
      .background-needs-card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        // filter: blur(10px);
        z-index: -1;
      }

      .needs-cards-view-date-top {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.019em;
        text-align: left;
        color: #fff;
      }

      .leads-title {
        // margin-top: 40px;
        // margin: 20px 0;
        margin: 0;
        margin-top: 25px;
        // margin-bottom: 52px;

        .tag {
          // text-transform: capitalize;
          font-weight: 400;
          letter-spacing: -0.3px;
          line-height: 24px;
          font-size: 16px;
          backdrop-filter: blur(0px);         

          white-space: nowrap;
          overflow: hidden;
          max-width: 320px;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // .even-div {
  //   margin-top: 0px;
  // }
  // .odd-div {
  //   margin-top: -50px;
  // }

  .even-div {
    position: relative;
    top: 25%;
  }
}

.abc {
  display: flex;
  gap: 20px;
  margin-top: -30px;
  .a1 {
    margin-bottom: -30px;
    height: 200px;
  }
  .a2 {
    margin-top: 30px;
    height: 200px;
  }
}
.abc-first {
  display: flex;
  gap: 20px;
  .a1 {
    margin-bottom: -30px;
    height: 200px;
  }
  .a2 {
    margin-top: 30px;
    height: 200px;
  }
}

.hh {
  color: black;
  padding: 20px;
  background: yellow;
  margin-bottom: 80px;
  position: fixed;
  top: 0;
  width: 100%;
}

.rtr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  position: absolute;
  right: 15px;
  left: 15px;
  bottom: 15px;
  // margin-top: 50px;

  .tag {
    // margin-right: 10px;
    padding: 4px 10px;
    border-radius: 40px;
    // background-color: pink;
    background: #ffffff59;
    backdrop-filter: blur(0px);

    font-size: 10px;
    font-weight: 400;
    letter-spacing: -0.19px;
  }

  .bottom-group-tag {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.header-leads {
  background-color: red;
  z-index: 1;
}

.lead_header {
  display: flex;
  align-items: center;
  color: #000;
  // position: sticky;
  top: 0;
  background: rgb(255, 255, 255);
  z-index: 2;
  position: fixed;
  width: 100%;

  img {
    cursor: pointer;
    padding: 15px 23px 15px 29px;
  }

  .txt {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .needs-top-margin-loader {
    // margin-right: 12px;
    margin: 15px 15px 15px 29px;
  }
}

@media (min-width: 450px) {
  .leads-div {
    max-width: 375px;
    width: 100%;
  }
}

.loader-margin-red {
  margin-top: 20px;
  margin-bottom: 10px;
    position: absolute;
   // transform: translate(-50%,-50%);
    left: 50%;
}

.bg-filter-needs-view {
  backdrop-filter: url(#filter);  
  border-radius: 12px; 
  height: 100%; 
  width: 110%; 
  margin: -15px -12px; 
  padding: 15px 12px; 
  position: absolute; 
  left: 12px;
}