.badges {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .badge {
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    overflow: hidden;
    .bastch-Shimmer {
      margin-top: 12px;
    }
    img {
      width: 31px;
      height: 31px;
      pointer-events: none;
      border-radius: 50%;
    }
  }
  .total-badge {
    color: #000;
    // font-family: Raleway-Regular;
    font-size: 14px;
    font-weight: 500;
  }
}
