.wrapper-container {
  border-radius: 18px;
  background-repeat: no-repeat;
  margin-bottom: 35px;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3) !important;

  // background-image: url("../../../assets/images/Rectangle\ 3813.png");
}

.card-body-1 {
  position: relative;
  // top: -20px;
  padding: 0 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% + 20px);
  border-radius: 18px;
  min-height: 144px;
  cursor: pointer;

  // bottom: -20px;
}
.card_position {
  position: relative;
  margin-bottom: 5px;
}
.quote-container {
  position: relative;

  img {
    width: 55px;
    height: 60px;
    position: absolute;
    top: -20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
    clip-path: polygon(0% 19%, 100% 19%, 100% 100%, 0% 80%);
  }
  .quote_profile {
    width: 55px;
    height: 60px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    clip-path: polygon(0% 19%, 100% 19%, 100% 100%, 0% 80%);
  }
  .date-container {
    justify-content: end;
    flex-grow: 1;
    .date_test {
      padding-top: 16px;

      font-family: "Poppins-medium";
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: right;
    }
    .pin-icon {
      height: 16px;
      width: 16px;
      margin-top: 18px;
      margin-left: 5px;
    }
  }
}

.profile-wrapper {
  padding: 11px;
  .user-profile {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    pointer-events: none;
  }
  .user_profile {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    pointer-events: none;
  }
  .profile_image{
    height: 24px;
    width: 24px;
  }

  .user-card {
    padding-left: 13px;
    overflow: hidden;
    .user_test {
      font-family: "Poppins-medium";
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .designation {
      font-size: 10px !important;
      font-weight: 400;
      font-family: "Poppins-regular";
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.testimonal-text {
  font-family: "Poppins-medium";
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0 20px;
}

.testimonal_text_profile {
  word-wrap: break-word;

  font-family: "Poppins-medium";
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.testi_profile_cont {
  margin: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonal-detail-text {
  font-size: 20px;
  font-family: "Poppins-medium";
  font-weight: 500;
  padding: 0 11px;
  text-align: left;
  line-height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  height: 65vh;
  overflow-wrap: anywhere;

  // height: 74vh;
  overflow-y: auto;
}
.brn-see-more {
  border: none;
  background: none;
  font-size: 17px;
  font-weight: 600;
}
