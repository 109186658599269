.bio {
  height: 100vh;
  background-color: rgb(255, 255, 255);
  padding-bottom: 25px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .bio-header {
    align-items: center;
    background: #fff;
    display: flex;
    /* height: 44px; */
    /* padding: 0 29px; */
    /* margin: 0 29px; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    // background: yellow;

    .bio-back {
      img {
        cursor: pointer;
        margin-right: 16px;
      }
    }

    .title {
      // font-family: "WorkSans-Regular";

      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    .back-icon-loader {
      margin: 14px 29px 14px 23px;
    }
  }

  .bioBack {
    cursor: pointer;
    /* margin-right: 23px; */
    padding: 15px 23px 15px 29px;
  }

  .about-me {
    padding: 20px 20px 0 20px;
    padding-top: 23px;

    .title {
      color: #000;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }

    .desc {
      font-family: "AvenirNextLTPro-Regular";
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 12px;
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }

  hr {
    border: 0.5px solid rgb(0 0 0 / 40%);
    margin: 15px 20px;
  }

  .hr-middle {
    margin: 0 !important;
  }

  .skills-title {
    color: #000;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
  }

  .skills-option-div {
    .skills-sub-title {
      color: #000;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    .all-skills {
      margin: 10px 20px;
      margin-bottom: 25px;
      margin-right: 0;
      display: flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .single-skill {
        color: white;
        white-space: nowrap;
        // width: 100%;
        background: #147bff;
        margin-right: 8px;
        padding: 5px 10px;
        border-radius: 14px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        // cursor: pointer;
      }

      .no-data-div {
        width: 100%;
        // margin-bottom: -20px;
      }
    }
  }

  .awards-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 20px;

    .awards-text {
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }

    .view-all {
      color: #e72d38;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }

  .award-icons {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding-bottom: 17px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .icon {
      margin-right: 20px;
      margin-bottom: 25px;

      img {
        height: 60px;
        width: auto;
        cursor: pointer;
      }
    }

    .list-item {
      display: flex;
      margin: 0;
      align-items: center;
      padding: 14px 0;
      cursor: pointer;

      .logo-div {
        img {
          width: 44px;
          height: 44px;
          pointer-events: none;
        }

        margin-right: 15px;
      }

      .description-div {
        .title1 {
          color: #313030;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* min-width: 50px; */
          max-width: 250px !important;
          white-space: nowrap;
          overflow: hidden;
        }

        .subtitle-div {
          display: flex;

          .subtitle1 {
            color: #848484;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            margin-top: 6px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 220px;
            text-overflow: ellipsis;
            margin-right: 10px;
          }

          .subtitle_withoutBlue1 {
            max-width: 240px;
          }

          .bluetick {

            //  margin-left: 10px;
            img {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  .edu-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    margin: 24px 20px;
    // padding-bottom: 19px;
  }

  .comp-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    margin: 24px 20px;
    // padding-bottom: 9px;
  }

  .blood-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;

    .title {
      color: #000;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }

    .desc {
      color: #1c090999;
      font-family: "AvenirNextLTPro-Medium";
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      // margin-top: 12px;
    }
  }

  .resume-div {
    box-shadow: 0px 4px 4px 0px #c0c0c040;
    margin: 24px 20px 20px 20px;

    border: 0.5px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 4px;

    .resume {
      display: flex;
      align-items: center;

      .logo {
        // padding: 5px 15px;
      }

      img {
        pointer-events: none;
        pointer-events: none;
        width: 44px;
        height: 44px;
        background: #f9f9f9;
        border-radius: 50%;
      }

      .title {
        color: #000;
        margin-left: 15px;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .icon {
      .resume-right-icon {
        height: 11px;
        width: 6px;
        margin-bottom: 6px;
        margin-right: 7px;
      }

      svg {
        color: #000 !important;
      }

      img {
        pointer-events: none;
      }
    }
  }

  .work-detail {
    .work-title-div {
      margin: 20px;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .work-title {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 360px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
        word-break: break-all;
        // text-transform: capitalize;
      }

      //.work-title:first-letter {text-transform: uppercase}
      .work-verify {
        color: #000;
        margin-left: 5px;
      }
    }
  }

  .work-desc-div {
    display: flex;
    align-items: center;
    margin: 0 20px;
    margin-top: 6px;

    .name {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: #1c090999;
      white-space: nowrap;
      overflow: hidden;
      max-width: 175px;
      text-overflow: ellipsis;
      margin-right: 3px;
    }

    .badge1 {
      color: #000;

      margin-right: 3px;
    }

    .city {
      color: #1c090999;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      white-space: nowrap;
      overflow: hidden;
      max-width: 175px;
      text-overflow: ellipsis;
      // text-transform: lowercase
    }
  }

  .year {
    // font-family: "AvenirNextLTPro-Regular";
    margin: 0 20px;
    margin-top: 8px;
    color: #1c0909;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 15px;
  }
}

.showing-img-loader {
  display: block !important;
}

.hiding-img-loader {
  display: none !important;
}

.resume-loader {
  margin-top: 14px;
  margin-bottom: 14px;
  margin-right: 14px;
  margin-left: 15px;
}

.education-margin {
  margin-bottom: 30px;
}

@media (min-width: 400px) and (max-width: 450px) {
  .bio {
    .award-icons {
      .list-item {
        .description-div {
          .title {
            max-width: 310px !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media (min-width: 370px) and (max-width: 399px) {
  .bio {
    .award-icons {
      .list-item {
        .description-div {
          .title {
            max-width: 280px !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media (min-width: 450px) {
  .bio {
    .award-icons {
      .list-item {
        .description-div {
          .title {
            max-width: 285px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media (max-width: 369px) {
  .bio {
    .award-icons {
      .list-item {
        .description-div {
          .title {
            max-width: 250px !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}