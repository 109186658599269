@font-face {
  font-family: "AvenirPro-regular";
  src: url("./assets/fonts/Avenir_Next_Pro/AvenirNextLTPro-Regular.otf")
    format("opentype");
}

@font-face {
  font-family: "AvenirPro-medium";
  src: url("./assets/fonts/Avenir_Next_Pro/AvenirNextLTPro-Medium.otf")
    format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Poppins-medium";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("opentype");
  /* You may include additional font properties if needed */
}

@font-face {
  font-family: "Poppins-regular";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Poppins-bold";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("opentype");
  /* You may include additional font properties if needed */
}

@font-face {
  font-family: "FuturaBoldFont";
  src: url("./assets/fonts/fonts/FuturaBoldFont.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "FuturaHeavyFont";
  src: url("./assets/fonts/fonts/FuturaHeavyFont.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "FuturaLightFont";
  src: url("./assets/fonts/fonts/FuturaLightFont.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "FuturaMedium";
  src: url("./assets/fonts/fonts/FuturaMedium.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Nunito-Black";
  src: url("./assets/fonts/fonts/Nunito-Black.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Nunito-Black";
  src: url("./assets/fonts/fonts/Nunito-Black.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Nunito-Bold";
  src: url("./assets/fonts/fonts/Nunito-Bold.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Nunito-ExtraLight";
  src: url("./assets/fonts/fonts/Nunito-ExtraLight.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Nunito-Light";
  src: url("./assets/fonts/fonts/Nunito-Light.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Nunito-Medium";
  src: url("./assets/fonts/fonts/Nunito-Medium.ttf") format("opentype");
  /* You may include additional font properties if needed */
}

@font-face {
  font-family: "WorkSans-Regular";
  src: url("./assets/fonts/fonts/WorkSans-Regular.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "WorkSans-Medium";
  src: url("./assets/fonts/fonts/WorkSans-Medium.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "AvenirNextLTPro-Light";
  src: url("./assets/fonts/fonts/AvenirNextLTPro-Light.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("./assets/fonts/fonts/AvenirNextLTPro-Regular.otf")
    format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("./assets/fonts/fonts/AvenirNextLTPro-Regular.otf")
    format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "AvenirNextLTPro-Medium";
  src: url("./assets/fonts/fonts/AvenirLTPro-Medium.otf")
    format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/fonts/Raleway-Regular.ttf")
    format("opentype");
  /* You may include additional font properties if needed */
}



@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Roboto:wght@300;400;500;700&display=swap");

* {
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  color: white;
  // touch-action: pan-x pan-y;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Mobile Styles */
.main-container {
  width: 100%;
  height: 100vh;
  // background-color: red;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 !important;
  overflow-y: auto;

  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  // background-position: center;
}

/* Tablet and Larger Screens Styles */
@media (min-width: 450px) {
  .main-container {
    max-width: 375px !important;
    // width: 100%;
    height: 100vh;
    // background-color: rgb(60, 253, 111);
    margin: 0 auto; /* Center the container horizontally */
  }
}

/* Styles for mobile devices */
/* You might want to use a max-width here instead of min-width for mobile */
@media (max-width: 419px) {
  .main-container {
    max-width: 100%; /* Allowing the container to take up full width */
    height: auto; /* Allowing the height to adjust based on content */
    /* Other mobile styles */
  }
}

@media (max-width: 349px) {
  /* Adjust styles for screens below 350px */
  .main-container {
    /* You might want to adjust these styles based on your design */
    max-width: 100%; /* Allow the container to take up full width */
    /* Other mobile styles */
  }
}

.offerings_screen {
  height: 95vh !important;
  border-radius: 18px 18px 0 0;

  .main_div_canvas {
    border-radius: 18px 18px 0 0;

    background-color: #be212a;
    height: 100vh;
    .wallpaper {
      height: 55vh;
      // background-color: yellow;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 90%;
        position: absolute;
        bottom: 0;
      }
    }

    .content_div_canvas {
      background-color: white;
      height: 30vh;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 40px;

      .txt1 {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.3px;
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #979797;
        text-align: center;
        margin-top: -15px;
        margin-bottom: 10px;
      }
      img {
        max-width: 180px;
        width: 100%;
        cursor: pointer;
      }
    }

    .top_canvas_section {
      text-align: center;
      position: relative;
      .skipOfferings {
        position: absolute;
        top: 10%;
        right: 10%;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
      }
      #popup_hr {
        border: 4px solid white;
        opacity: 1;
        width: 32%;
        margin: 0 auto;
        border-radius: 5px;
        margin-top: 5%;
      }
      img {
        margin-top: 10% !important;
        width: 35%;
      }
    }
  }
}

.Toastify__toast {
  .Toastify__toast-body {
    div {
      color: #757575 !important;
    }
  }
  button {
    svg {
      path {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
}


.show-img-loader {
  display: block !important;
}
.hide-img-loader {
  display: none !important;
}


@media (min-width: 451px) {
  .offerings_screen {
    max-width: 375px !important;
    margin: 0 auto;
  }
  .offcanvas-backdrop.show {
    width: 375px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


.offcanvas-backdrop{
  // background-color: transparent !important;
}