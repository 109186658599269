
.container-wrapper-no {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.no-testimonal {
  font-family: "AvenirPro-regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
  color: #000000cc;
}

.centered-div-test {
 
}

.height-100{
  height: 100%;
}

.no-search-img{
  margin-right: 61px;
  margin-left: 21px;
}