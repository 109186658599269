.header-without_Border {
  // padding: 20px 20px 20px 20px;
  display: flex;
  align-items: end;
  background: red;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
  .searchbar-main {
    position: absolute;
    width: 285px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    background: rgb(234, 236, 242);
    top: 7px;
    left: 60px;
    border-width: 0px, 0px, 0px, 0px;
    border-style: none;
    align-items: center;
    border-color: rgb(35, 35, 35);
    display: flex;
    input {
      background: transparent;
      outline: none;
      width: 265px;
      border: none;
      color: rgba(0, 0, 0, 1);
    //  font-family: "WorkSans-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      // letter-spacing: 0em;
      text-align: left;
      margin-left: 5px;
      text-size-adjust:none;
    
    }
    input::placeholder {
      color:  #B7B7B7;
      // font-family: "WorkSans-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
     
      text-overflow: ellipsis;

    }

    input:focus {
      text-size-adjust:none;
    }
  }

  .Search-img {
   // margin-top: 13px;
    margin-left: 12px;
    width: 18px;
    height: 18px;
  }
  .close-img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
   // margin-top: 11px;
    cursor: pointer;
  }

  .view-back1 {
    cursor: pointer;
    margin-top: 5px;
    padding: 15px 20px 15px 29px;
    z-index: 1;
  }
}
.view-all-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
  padding: 0 !important;
  // border-bottom: 0.5px solid #00000029;

  .view-back1 {
    cursor: pointer;
    padding: 15px 23px 15px 29px;
  }
  .back-icon-awar-spinner {
   // margin-right: -8px;
   margin-left: 29px;
  }
  .title1 {
    // font-family: "WorkSans-Regular";

    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    //margin-left: 16px;
  }
  .search-icon {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 20px;
    width: 22px;
    height: 22px;
  }
}
