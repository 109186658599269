.comments-body {
  overflow-y: auto;
  height: calc(100vh - 55px);
}

.screen-cont{
  padding: 10px 20px 0px 20px;

}

.no-comments {
  height: calc(100vh - 55px);
  display: flex;
  align-items: center;
  justify-content: center;
}
