.profile-picture-view {
    background-color: rgb(255, 255, 255);
    color: #000;
    height: 100vh;
    position: relative;
  
    .profile-pic-header {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background: white;
  
      .profile-pic-back-spinner {
        margin: 14px 20px 14px 29px;
      }

      .profile-pic-back {
        img {
          cursor: pointer;
          padding: 15px 23px 15px 29px;
        }
      }
  
      .profile-pic-title {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 290px;
      }

      .profile-pic-header-skeleton {
        margin-left: -4px;
      }
    }

    .profile-picture-container {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90vh;

        img {
            max-width: 450px;
            width: 100%;
            height: auto;
            pointer-events: none;
        }
    }

    .profile-pic-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}